<template>
    <nav class="fixed top-0 z-50 w-full bg-white shadow-sm">
        <div class="px-3 md:px-10 flex justify-between items-center w-full">
            <div class="flex items-center justify-between h-navbar-y">
                <Link :href="route('index')">
                    <ApplicationLogo class="py-4 text-primary text-[10rem]" />
                </Link>
            </div>

            <div>
                <div class="dropdown dropdown-bottom dropdown-end">
                <div tabindex="0" role="button" class="btn btn-outline border-2 py-1 px-2 text-lg m-1 uppercase">{{ $t('language.flags.' + currentLocale) + ' ' + currentLocale }}</div>
                  <ul tabindex="0" class="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
                    <li v-for="(locale, index) in supportedLocales" :key="index">
                        <a @click="changeLocale(locale)">
                            {{ $t('language.flags.' + locale) + ' ' + $t('language.' + locale) }}
                        </a>
                    </li>
                  </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
import { Link, router, usePage } from '@inertiajs/vue3';
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import { computed } from 'vue';

const supportedLocales = computed(() => {
    return usePage().props.config.supportedLocales;
})

const currentLocale = computed(() => {
    return usePage().props.config.currentLocale;
})

const changeLocale = (locale) => {
    router.post(route('set-locale'), { locale: locale}, {
        onSuccess: () => {
            window.location.reload();
        }
    });
}
</script>
